<template>
  <div id="add-event-image">
    <v-card>
      <v-app-bar
        color="primary"
        dense
        dark
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-toolbar-title>{{ PageTitle }}</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-container style="overflow: hidden">
        <v-card-title></v-card-title>
        <v-card-text>
          <p></p>
          <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
            <b-spinner variant="primary" type="grow" label="Loading..."></b-spinner>
          </div>
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <v-btn
                elevation="30"
                shaped
                tile
                small
                class="font-size-h6 mr-3 white--text"
                @click.prevent="refreshPageData"
                color="#a4c639"
              >Refresh</v-btn>
              <br />
            </v-col>
          </v-row>
          <v-form ref="form1" v-model="valid1" lazy-validation v-on:submit.prevent="submitForm">
            <v-container>
              <v-row wrap>
                <v-col cols="12" md="12">
                  <p>
                    <span class="text-danger">*</span> indicates required
                    field
                  </p>
                </v-col>
                <v-col cols="12" sm="6" lg="4" md="4">
                  <label>
                    <h6>
                      <span class="text-danger">*</span> State
                    </h6>
                  </label>
                  <v-autocomplete
                    :reduce="(option) => option.value"
                    v-model="StatesCode"
                    :rules="StatesCodeRules"
                    :items="StatesCodeOptions"
                    :loading="StatesCodeOptionsLoading"
                    dense
                    clearable
                    required
                    outlined
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" lg="4" md="4">
                  <label>
                    <h6>
                      <span class="text-danger">*</span> District
                    </h6>
                  </label>
                  <v-autocomplete
                    :reduce="(option) => option.value"
                    :loading="DistrictCodeOptionsLoading"
                    :items="DistrictCodeOptions"
                    :rules="DistrictCodeRules"
                    v-model="DistrictCode"
                    dense
                    clearable
                    required
                    outlined
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" lg="4" md="4">
                  <label>
                    <h6>
                      City Name
                      <small>(optional)</small>
                    </h6>
                  </label>
                  <v-text-field
                    v-model="CityName"
                    :rules="CityNameRules"
                    placeholder="City Name"
                    hint="Enter image City Name"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row wrap>
                <v-col align="center" cols="12" md="12">
                  <v-btn
                    :disabled="!valid1"
                    @click.prevent="submitForm"
                    :loading="SubmitFlag"
                    elevation="30"
                    shaped
                    tile
                    small
                    color="#1db954"
                    class="font-size-h6 px-10 mr-3 white--text"
                  >Submit</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {},
  props: {
    AddRecordPrompt: {
      type: Boolean,
      required: true
    },
    recordData: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      valid1: true,
      LoadingFlag: false,
      SubmitFlag: false,

      row: {},

      StatesCodeRules: [v => !!v || "State is required"],
      StatesCode: "",
      StatesCodeOptions: [],
      StatesCodeOptionsLoading: false,

      DistrictCodeRules: [v => !!v || "District is required"],
      DistrictCode: "",
      DistrictCodeOptions: [],
      DistrictCodeOptionsLoading: false,

      CityName: "",
      CityNameRules: [v => !!v || "City Name is required"],

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      JciYearCode: "",
      YearName: "",
      NextYearCode: "",
      NextYearName: ""
    };
  },
  computed: {},
  watch: {
    PageInfo: function() {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;
        this.CurrentYearId = CurrentJciYearId;

        this.getStatesCodeOptions();
        this.getCityDetails();
      }
    },
    StatesCodeOptions: function() {
      console.log("watch StatesCodeOptions");
      this.StatesCodeOptionsLoading = false;
      this.LoadingFlag = false;
    },
    DistrictCodeOptions: function() {
      console.log("watch DistrictCodeOptions");
      this.DistrictCodeOptionsLoading = false;
    },
    StatesCode: function() {
      console.log("watch StatesCode");
      this.getDistrictCodeOptions();
    }
  },
  mounted() {},
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "city",
        Action: "edit"
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hideDialog");
    },
    getCityDetails() {
      console.log("getCityDetails is called");

      var CityCode = this.recordData.CityId;
      console.log("CityCode=" + CityCode);

      if (CityCode != "") {
        var upload = {
          UserInterface: 1,
          CityCode: CityCode
        };
        console.log({ upload });

        this.LoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/city/show";
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token
          },
          onUploadProgress: function(progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this)
        })
          .then(function(response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.LoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);

            if (flag == 1) {
              thisIns.StatesCode = records.StateId;
              thisIns.DistrictCode = records.DistrictId;
              thisIns.CityName = records.CityName;
              thisIns.CityCode = records.CityId;
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function(error) {
            console.log("error=" + error);
            thisIns.LoadingFlag = false;
          });
      } else {
        var message = "";
        if (CityCode == "") {
          message += "City code should not be empty.";
        }
        this.sweetAlert("error", message, false);
      }
    },
    getStatesCodeOptions() {
      console.log("getStatesCodeOptions called");
      this.StatesCodeOptionsLoading = true;
      this.StatesCode = "";
      this.DistrictCode = "";
      var selectbox1_source = "StatesCode";
      var selectbox1_destination = "StatesCodeOptions";
      var selectbox1_url = "api/states/options";
      var selectbox1_conditions_array = {
        UserInterface: 1
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getDistrictCodeOptions() {
      console.log("getDistrictCodeOptions called");
      this.DistrictCodeOptionsLoading = true;
      var StatesCode = this.StatesCode;
      console.log("StatesCode=" + StatesCode);
      if (StatesCode != "") {
        var selectbox1_source = "DistrictCode";
        var selectbox1_destination = "DistrictCodeOptions";
        var selectbox1_url = "api/district/options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
          StatesCode: StatesCode
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "State should not be empty";
        this.alert = {
          flag: true,
          color: "error",
          message: message
        };
      }
    },
    submitForm() {
      console.log("submitForm is called");

      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      var DistrictCode = this.DistrictCode;
      var CityName = this.CityName;
      var CityCode = this.CityCode;

      console.log(
        "DistrictCode=" +
          DistrictCode +
          ", CityName=" +
          CityName +
          ", CityCode=" +
          CityCode
      );

      if (validate1 && DistrictCode != "" && CityName != "" && CityCode != "") {
        var upload = {
          CityCode: CityCode,
          DistrictCode: DistrictCode,
          CityName: CityName
        };
        console.log({ upload });

        this.progessStart = 1;
        this.SubmitFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/city/update";
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token
          },
          onUploadProgress: function(progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this)
        })
          .then(function(response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SubmitFlag = false;
            thisIns.progessStart = 0;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);

            if (flag == 1) {
              thisIns.sweetAlert("success", output, true);
              thisIns.closePrompt();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function(error) {
            console.log("error=" + error);
            thisIns.SubmitFlag = false;
            thisIns.progessStart = 0;
          });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields";
        }
        if (CityCode == "") {
          message += "City code should not be empty.";
        }
        this.sweetAlert("error", message, false);
      }
    }
  },
  beforeMount() {
    this.refreshPageData();
  }
};
</script>
<style lang="scss">
#add-event-image {
  .notes {
    color: red;
    font-size: 18px;
    font-weight: 500;
    line-height: 2;
  }
  .notes-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .notes-content {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
}
</style>