<template>
  <div id="add-event-image">
    <v-card>
      <v-app-bar
        color="primary"
        dense
        dark
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-toolbar-title>{{ PageTitle }}</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-container style="overflow: hidden">
        <v-card-title></v-card-title>
        <v-card-text>
          <p></p>
          <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
            <b-spinner
              variant="primary"
              type="grow"
              label="Loading..."
            ></b-spinner>
          </div>
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <v-btn
                elevation="30"
                shaped
                tile
                small
                class="font-size-h6 mr-3 white--text"
                @click.prevent="refreshPageData"
                color="#a4c639"
                >Refresh</v-btn
              >
              <br />
            </v-col>
          </v-row>
          <v-form
            ref="form1"
            v-model="valid1"
            lazy-validation
            v-on:submit.prevent="submitForm"
          >
            <v-container>
              <v-row wrap>
                <v-col cols="12" md="12">
                  <p>
                    <span class="text-danger">*</span> indicates required field
                  </p>
                </v-col>
                <v-col cols="12" md="3">
                  <label
                    ><h6><span class="text-danger">*</span> Type</h6>
                  </label>
                  <v-autocomplete
                    :reduce="(option) => option.value"
                    v-model="Type"
                    :rules="TypeRules"
                    :items="TypeOptions"
                    :loading="TypeOptionsLoading"
                    dense
                    clearable
                    required
                    outlined
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="3">
                  <label
                    ><h6>
                      <span class="text-danger">*</span> From Date
                    </h6></label
                  >
                  <v-menu
                    v-model="menu1"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    lazy
                    transition="scale-transition"
                    offset-y
                    full-width
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        required
                        v-model="FromDate"
                        label="From Date"
                        readonly
                        v-on="on"
                        background-color="#F3F6F9"
                        class="form-control form-control-lg form-control-solid"
                        validate-on-blur
                        solo
                        dense
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="FromDate"
                      @input="menu1 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="3">
                  <label
                    ><h6><span class="text-danger">*</span> To Date</h6></label
                  >
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    lazy
                    transition="scale-transition"
                    offset-y
                    full-width
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        required
                        v-model="ToDate"
                        label="To Date"
                        readonly
                        v-on="on"
                        background-color="#F3F6F9"
                        class="form-control form-control-lg form-control-solid"
                        validate-on-blur
                        solo
                        dense
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="ToDate"
                      @input="menu2 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6" lg="3" md="3">
                  <label>
                    <h6><span class="text-danger">*</span> Name</h6>
                  </label>
                  <v-text-field
                    v-model="Name"
                    :rules="NameRules"
                    placeholder="Name"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" lg="3" md="3">
                  <label>
                    <h6><span class="text-danger">*</span> Short Code</h6>
                  </label>
                  <v-text-field
                    v-model="ShortCode"
                    :rules="ShortCodeRules"
                    placeholder="Short Code"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" lg="3" md="3">
                  <label>
                    <h6><span class="text-danger">*</span> Description</h6>
                  </label>
                  <v-text-field
                    v-model="Description"
                    :rules="DescriptionRules"
                    placeholder="Description"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <label>
                    <h6><span class="text-danger">*</span> Sub Total</h6>
                  </label>
                  <v-text-field
                    @input="calculateTotalAmount"
                    v-model="SubTotal"
                    :rules="SubTotalRules"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <label>
                    <h6><span class="text-danger">*</span> Tax</h6>
                  </label>
                  <v-text-field
                    @input="calculateTotalAmount"
                    v-model="Tax"
                    :rules="TaxRules"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <label>
                    <h6>
                      <span class="text-danger">*</span> Total Amount
                    </h6> </label
                  ><br />
                  <h4>{{ TotalAmount }}</h4>
                </v-col>
                <v-col cols="12" sm="6" lg="3" md="3">
                  <label>
                    <h6><span class="text-danger">*</span> Status</h6>
                  </label>
                  <v-switch
                    inset
                    v-model="Status"
                    :label="`${Status ? 'Active' : 'Inactive'}`"
                  ></v-switch>
                </v-col>
              </v-row>
              <v-row wrap>
                <v-col align="center" cols="12" md="12">
                  <v-btn
                    :disabled="!valid1"
                    @click.prevent="submitForm"
                    :loading="SubmitFlag"
                    elevation="30"
                    shaped
                    tile
                    small
                    color="#1db954"
                    class="font-size-h6 px-10 mr-3 white--text"
                    >Submit</v-btn
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {},
  props: {
    AddRecordPrompt: {
      type: Boolean,
      required: true,
    },
    recordData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      valid1: true,
      LoadingFlag: false,
      SubmitFlag: false,

      row: {},

      ShortCode: "",
      ShortCodeRules: [(v) => !!v || "short code is required"],

      Name: "",
      NameRules: [(v) => !!v || " Name is required"],

      Description: "",
      DescriptionRules: [(v) => !!v || "description  is required"],

      Status: 1,

      SubTotal: "",
      SubTotalRules: [(v) => !!v || "Sub total is required"],

      Tax: "",
      TaxRules: [(v) => !!v || "Tax is required"],

      TotalAmount: "",

      Type: "",
      TypeRules: [(v) => !!v || "type Name is required"],
      TypeOptions: [
        { value: "", text: "Select Type" },
        { value: 1, text: "Life Member" },
        { value: 2, text: "Privilege Donar" },
      ],

      FromDate: "",
      FromDateRules: [(v) => !!v || "From date is required"],
      menu1: false,

      ToDate: "",
      ToDateRules: [(v) => !!v || "To date is required"],
      menu2: false,

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      JciYearCode: "",
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
    };
  },
  computed: {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;
        this.CurrentYearId = CurrentJciYearId;
        this.getLifeMemberPlanDetails();
        // this.getStatesCodeOptions();
        // this.getCityDetails();
      }
    },
    StatesCodeOptions: function () {
      console.log("watch StatesCodeOptions");
      this.StatesCodeOptionsLoading = false;
      this.LoadingFlag = false;
    },
    DistrictCodeOptions: function () {
      console.log("watch DistrictCodeOptions");
      this.DistrictCodeOptionsLoading = false;
    },
    StatesCode: function () {
      console.log("watch StatesCode");
      this.getDistrictCodeOptions();
    },
  },
  mounted() {},
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "life_member_plan",
        Action: "edit",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    calculateTotalAmount() {
      console.log("calculateTotalAmount called");
      var SubTotal = this.SubTotal;
      SubTotal = isNaN(SubTotal) ? 0 : SubTotal;
      var Tax = parseFloat(this.Tax);
      Tax = isNaN(Tax) ? 0 : Tax;
      console.log("SubTotal=" + SubTotal + ", Tax" + Tax);

      var TaxAmount = (SubTotal * Tax) / 100;
      console.log({ TaxAmount });

      var TotalAmount = parseFloat(SubTotal) + parseFloat(TaxAmount);
      console.log({ TotalAmount });
      this.TotalAmount = isNaN(TotalAmount) ? 0 : TotalAmount;
    },
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hideDialog");
    },
    getLifeMemberPlanDetails() {
      console.log(" getLifeMemberPlanDetails is called");

      var SeniorLifeMemberPlan = this.recordData.SeniorLifeMemberPlanId;
      console.log("SeniorLifeMemberPlan=" + SeniorLifeMemberPlan);

      if (SeniorLifeMemberPlan != "") {
        var upload = {
          UserInterface: 2,
          SeniorMemberLifeMemberPlanId: SeniorLifeMemberPlan,
        };
        console.log({ upload });

        this.LoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/senior/life-member-plan/show";
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.LoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            if (flag == 1) {
              thisIns.SeniorMemberLifeMemberPlanId = records.PlanId;
              thisIns.Type = records.PlanType;
              thisIns.Name = records.PlanName;
              thisIns.ShortCode = records.ShortCode;
              thisIns.Description = records.Description;
              thisIns.SubTotal = records.SubTotal;
              thisIns.ToDate = records.ToDate;
              thisIns.FromDate = records.FromDate;
              thisIns.Tax = records.Tax;
              thisIns.TotalAmount = records.PlanAmount;
              thisIns.Status = records.ActiveStatus;
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.LoadingFlag = false;
          });
      } else {
        var message = "";
        if (SeniorLifeMemberPlan == "") {
          message += "senior life member plan code should not be empty.";
        }
        this.sweetAlert("error", message, false);
      }
    },
    getStatesCodeOptions() {
      console.log("getStatesCodeOptions called");
      this.StatesCodeOptionsLoading = true;
      this.StatesCode = "";
      this.DistrictCode = "";
      var selectbox1_source = "StatesCode";
      var selectbox1_destination = "StatesCodeOptions";
      var selectbox1_url = "api/states/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getDistrictCodeOptions() {
      console.log("getDistrictCodeOptions called");
      this.DistrictCodeOptionsLoading = true;
      var StatesCode = this.StatesCode;
      console.log("StatesCode=" + StatesCode);
      if (StatesCode != "") {
        var selectbox1_source = "DistrictCode";
        var selectbox1_destination = "DistrictCodeOptions";
        var selectbox1_url = "api/district/options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
          StatesCode: StatesCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "State should not be empty";
        this.alert = {
          flag: true,
          color: "error",
          message: message,
        };
      }
    },
    submitForm() {
      console.log("submitForm is called");

      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);
      var PlanId = this.recordData.SeniorLifeMemberPlanId;
      console.log("PlanId" + PlanId);

      var PlanType = this.Type;
      var ShortCode = this.ShortCode;
      var PlanName = this.Name;
      var Description = this.Description;
      var FromDate = this.FromDate;
      var ToDate = this.ToDate;
      var SubTotal = this.SubTotal;
      var Tax = this.Tax;
      var TotalAmount = this.TotalAmount;
      var ActiveStatus = this.Status;
      if (validate1) {
        var upload = {
          SeniorLifeMemberPlanId: PlanId,
          PlanType: PlanType,
          ShortCode: ShortCode,
          PlanName: PlanName,
          Description: Description,
          FromDate: FromDate,
          ToDate: ToDate,
          SubTotal: SubTotal,
          Tax: Tax,
          Amount: TotalAmount,
          ActiveStatus: ActiveStatus,
        };
        console.log({ upload });
        this.progessStart = 1;
        this.SubmitFlag = true;
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/senior/life-member-plan/update";
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;
        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SubmitFlag = false;
            thisIns.progessStart = 0;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);

            if (flag == 1) {
              thisIns.sweetAlert("success", output, true);
              thisIns.closePrompt();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SubmitFlag = false;
            thisIns.progessStart = 0;
          });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields";
        }
        if (CityCode == "") {
          message += "senior lie member plan code should not be empty.";
        }
        this.sweetAlert("error", message, false);
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
<style lang="scss">
#add-event-image {
  .notes {
    color: red;
    font-size: 18px;
    font-weight: 500;
    line-height: 2;
  }
  .notes-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .notes-content {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
}
</style>